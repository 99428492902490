import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import esES from 'antd/locale/es_ES';
import React, { useState, lazy } from 'react';
import Dashboard from './components/Dashboard';
import { ConfigProvider, Space, Button, notification, message } from 'antd';

// Lazy imports
const Home = lazy(() => import('./components/Home'));
const Privacy = lazy(() => import('./components/Privacy'));
const AboutUs = lazy(() => import('./components/AboutUs'));
const Products = lazy(() => import('./components/Products'));
const Testimonials = lazy(() => import('./components/Testimonials'));

export default function App() {
  // State
  const [component, setComponent] = useState("home"); // Set actual page to render

  // Color selection
  const colorPrimary = '#00722d';

  // Notification and messages
  const [not_api, notificationContextHolder] = notification.useNotification();
  const [msg_api, msgContextHolder] = message.useMessage();
  
  // Display messages
  const showMessage = () => {
    msg_api.success('Success!');
  };

  // Display notification
  const showNotification = () => {
    not_api.info({
      message: `Notification topLeft`,
      description: 'Hello, Ant Design!!',
      placement: 'topRight',
    });
  };

  // Select component to display
  const myComponent = () => {
    if(component==='home') return <Home />;
    else if(component==='privacy') return <Privacy />;
    else if(component==='aboutUs') return <AboutUs />;
    else if(component==='products') return <Products />;
    else if(component==='testimonials') return <Testimonials />;
    else return <>
                <Space>
                  <Button type="primary" onClick={showMessage}>
                    Open message
                  </Button>
                  <Button type="primary" onClick={showNotification}>
                    Open notification
                  </Button>
                </Space>
                </>;
  }

  return (
    <ConfigProvider
      locale={esES}
      theme={{
        token: {
          fontFamily: 'Lexend_Regular',
          colorPrimary: colorPrimary, // primary color theme
          //colorLink: '#261447', // link color
          //colorSuccess: '#52c41a', // success state color
          //colorWarning: '#faad14', // warning state color
          //colorError: '#dd1c1a', // error state color

          //fontSize: '14px', // major text font size
          //colorTextHeading: 'rgba(0, 0, 0, 0.85)', // heading text color
          //colorText: 'rgba(0, 0, 0, 0.65)', // major text color
          //colorTextSecondary: 'rgba(0, 0, 0, 0.45)', // secondary text color
          //colorTextQuaternary: 'rgba(0, 0, 0, 0.25)', // disable state color
          
          //borderRadius: '2px', // major border radius
          //colorBorder: '#ebebeb', // major border color
          //colorBgContainer: '#59c3c3', // Background sider
          //colorBgLayout: '#ebebeb', // Background layout
          //colorText: '#ffffff', // Color of text
        },
      }}
    >
      {notificationContextHolder}
      {msgContextHolder}
      <Router>
        <Routes>
          <Route path="/" 
                element={<Dashboard component={component} setComponent={setComponent} >
                            {myComponent()}
                          </Dashboard>} 
          />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

