import 'boxicons';
import './index.css';
import Parse from 'parse';
import { useWindowSize } from 'react-use';
import { MenuOutlined } from '@ant-design/icons';
import addPropsToChildren from '../../utils/addProps';
import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Layout, Grid, Modal, Spin, Image, theme, Space, Dropdown, Breadcrumb, Form, Input, Button } from 'antd';

const { useBreakpoint } = Grid;
const { Header, Content, Footer } = Layout; 

const use100vh = () => {
  const ref = React.useRef();
  const { height } = useWindowSize();

  React.useEffect(
    () => {
      if (!ref.current) {
        return;
      }
      ref.current.style.height = height + 'px';
    },
    [height],
  );

  return ref;
}

const Dashboard = ({ children, component, setComponent }) => {
  // Ref to adapt to mobile view 
  const ref = use100vh();

  // Contact Modal
  const [modal, modalContextHolder] = Modal.useModal();

  // Create Menu Element
  const menuItem = ({icon, color, span}) => {
    let aref = span.replace(' ', '');
    
    if(icon){
      return (<a href={'#' + aref}><Space style={{color}}><box-icon color={color} name={icon} type='solid' /><span>{span}</span></Space></a>);
    }
    return (<a href={'#' + aref}><span style={{color: color}}>{span}</span></a>);
  }

  // BreakPoint
  const screens = useBreakpoint();

  // Theme
  const {
    token: { colorPrimary },
  } = theme.useToken();

  // Form Hook
  const [form] = Form.useForm();

  // Handle loading
  const [ isLoading, setIsLoading ] = useState(false);

  // Handle Menu
  const [ page, setPage ] = useState('home');
  const [ item, setItem ] = useState(menuItem({color: 'white', span: 'Home'}));

  const myRef = useRef(null);
  
  // Handle scroll
  const scrollToRef = (ref) => {
    ref.current.scroll({
      top: 0,
      behavior: 'smooth'
    });
  };

  // useEffect
  useEffect(() => {
    if(page==='contact') {
      showModal();
      setPage(component)
    }
    else setComponent(page);
  }, [page]);

  // Display modal
  const showModal = () => {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    // Control state
    const saveCustomer = (myModal) => {
        // Get form values
        const latestValues = form.getFieldsValue(true);
        
        // Update changes in new Product
        const Customer = Parse.Object.extend("PotentialCustomer");
        const potentialCustomer = new Customer();

        Object.keys(latestValues).forEach((key) => {
            potentialCustomer.set(key, latestValues[key]);
        });

        // Upload to database
        potentialCustomer.save()
        .then((result) => {
            setIsLoading(false);
            modal.success({title: 'Solicitud en proceso', content: 'Un asesor se pondrá en contacto contigo'});
            form.resetFields(); // Reset forms before set default values
            myModal.destroy();
        }, (error) => {
            setIsLoading(false);
            modal.error({title: 'Algo salió mal', content: 'Por favor intenta nuevamente'});
            form.resetFields(); // Reset forms before set default values
        });
    };

    const newCustomerRequest = (myModal) => {
        form.validateFields()
        .then(values => {
            setIsLoading(true);
            saveCustomer(myModal);
        })
        .catch(errorInfo => {
            modal.error({title: 'Revisa el formulario', content: 'Hay datos faltantes o erroneos'});
        });
    };

    const showModalContent = () => {
        if(isLoading) return (<Spin size="large"/>);

        else {
            return(
            <Form
                {...formItemLayout}
                form={form}
                name="newCustomer"
                requiredMark={false}
                layout='horizontal'
                scrollToFirstError
                >
                <Form.Item
                        name="name"
                        label="Nombre"
                        rules={[
                            {
                                min: 5,
                                message: '¿Quién nos contacta?',
                            },
                            {
                                required: true,
                                message: '¿Quién nos contacta?',
                            }
                        ]}
                    >
                        <Input/>
                </Form.Item>
                <Form.Item
                        name="email"
                        label="Correo"
                        rules={[
                            {
                                pattern: new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+[.]+[a-zA-Z]{2,}$'),
                                message: 'El correo no es válido',
                            },
                            {
                                required: true,
                                message: 'El correo no es válido',
                            }
                            ]}
                    >
                        <Input/>
                </Form.Item>
                <Form.Item
                        name="phone"
                        label="Teléfono"
                        rules={[
                            {
                                pattern: new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/),
                                message: 'Ingresa un número de teléfono válido',
                            },
                            {
                                required: true,
                                message: 'Ingresa un número de teléfono válido',
                            }
                        ]}
                    >
                        <Input/>
                </Form.Item>
                <Form.Item
                        name="msg"
                        label="Mensaje"
                        rules={[
                            { 
                                min: 20, 
                                message: 'Cuéntanos un poco más' 
                            },
                            {
                                required: true,
                                message: 'Cuéntanos un poco más',
                            }
                        ]}
                >
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} placeholder="Cuéntanos un poco acerca de lo que buscas"/>
                </Form.Item>
            </Form>
            );
        }
    };
    
    const request = modal.info({
      title: 'Pérmitenos ayudarte',
      content: showModalContent(),
      footer: <div style={{textAlign:'center'}}>
                <Space>
                  <Button key="back" onClick={() => request.destroy()}>
                      Cancelar
                  </Button>
                  <Button key="update" type="primary" onClick={() => newCustomerRequest(request)}>
                      Solicitar
                  </Button>
                </Space>
              </div>
    });
  };

  const navigation = [
    { 
      key: 1,
      label: menuItem({icon: 'home', color: colorPrimary, span: 'Home'}),
      title: menuItem({icon: 'home', color: 'white', span: 'Home'}),
      onClick: () => {
        setPage('home'); 
        setItem(menuItem({color: 'white', span: 'Home'})); 
        scrollToRef(myRef);
      },
    },
    {
      key: 2,
      label: menuItem({icon: 'info-circle', color: colorPrimary, span: 'Acerca de'}),
      title: menuItem({icon: 'info-circle', color: 'white', span: 'Acerca de'}),
      onClick: () => {
        setPage('aboutUs'); 
        setItem(menuItem({color: 'white', span: 'Acerca de'})); 
        scrollToRef(myRef);
      },
    },
    {
      key: 3,
      label: menuItem({icon: 'leaf', color: colorPrimary, span: 'Productos'}),
      title: menuItem({icon: 'leaf', color: 'white', span: 'Productos'}),
      onClick: () => {
        setPage('products'); 
        setItem(menuItem({color: 'white', span: 'Productos'})); 
        scrollToRef(myRef);
      },
    },
    {
      key: 4,
      label: menuItem({icon: 'message-check', color: colorPrimary, span: 'Testimonios'}),
      title: menuItem({icon: 'message-check', color: 'white', span: 'Testimonios'}),
      onClick: () => {
        setPage('testimonials'); 
        setItem(menuItem({color: 'white', span: 'Testimonios'})); 
        scrollToRef(myRef);
      },
    },
    {
      key: 5,
      label: menuItem({icon: 'phone', color: colorPrimary, span: 'Contacto'}),
      title: menuItem({icon: 'phone', color: 'white', span: 'Contacto'}),
      onClick: () => {setPage('contact'); setItem(menuItem({color: 'white', span: 'Contacto'}))},
    },
    {
      key: 6,
      label: menuItem({icon: 'universal-access', color: colorPrimary, span: 'Aviso de privacidad'}),
      title: menuItem({icon: 'universal-access', color: 'white', span: 'Aviso de privacidad'}),
      onClick: () => {
        setPage('privacy'); 
        setItem(menuItem({color: 'white', span: 'Aviso de privacidad'}));
        scrollToRef(myRef);
      },
    }
  ]

  const myMenu = () => {
    if(screens['lg']===true || screens['xl']===true || screens['xll']===true){
      return(
          <>
            <Image style={{marginBottom: 25, marginLeft: '5rem'}} preview={false} src={'https://ecolistico.com/img/logo-white.png'} alt={'Ecolistico'} width={250} height={42}/>
            <Breadcrumb
              style={{ marginRight: '2rem' }}
              items={navigation}
            />
          </>    
      );
    }
    else if(screens['md']===true){
      return(
        <>
          <Image style={{marginBottom: 25, marginLeft: '2rem'}} preview={false} src={'https://ecolistico.com/img/logo-white.png'} alt={'Ecolistico'} width={225} height={38}/>
          <div style={{ marginRight: '2rem' }}>
            <Dropdown menu={{ items: navigation }} arrow={true}>
              <Space>
                {item}
                <MenuOutlined style={{color:'white'}}/>
              </Space>
            </Dropdown>
          </div>
        </>
      );
    }
    return (
      <>
        <Image style={{marginBottom: 25, marginLeft: '1rem'}} preview={false} src={'https://ecolistico.com/img/logo-white.png'} alt={'Ecolistico'} width={200} height={34}/>
        <div style={{ marginRight: '1rem' }}>
            <Dropdown menu={{ items: navigation }} arrow={true}>
              <Space>
                {item}
                <MenuOutlined style={{color:'white'}}/>
              </Space>
            </Dropdown>
          </div> 
      </>
      );
  } 
  return (
      <Layout style={{height: "100vh", overflow: 'hidden'}} ref={ref}>
        {modalContextHolder}
        <Header style={{backgroundColor: colorPrimary }} className="titleBar">
            {myMenu()}
        </Header>
        <Layout ref={myRef} style={{ overflowX: 'hidden', overflowY: 'auto' }}>
          <Content>
              <Suspense
                fallback={<Layout>
                            <Layout.Footer className="footer">
                              <Spin size="large"/>
                            </Layout.Footer>
                          </Layout>}>
                {addPropsToChildren(children, { })}
              </Suspense>
          </Content>
        </Layout>
        <Footer className="footer">
            <Space>
              <span style={{ fontSize: '1.5rem'}}>Encuentranos en </span>
              <a href='https://wa.me/9988428757?text=¡Hola%20Ecolistico!%20Me%20puedes%20ayudar' aria-label="Whatsapp"><box-icon type='logo' color={colorPrimary} name='whatsapp' /></a>
              <a href='https://www.instagram.com/ecolistico/' aria-label="Facebook"><box-icon type='logo' color={colorPrimary} name='instagram-alt' /></a>
              <a href='https://www.facebook.com/profile.php?id=100093063186408&mibextid=ZbWKwL' aria-label="Instagram"><box-icon type='logo' color={colorPrimary} name='facebook-circle' /></a>
            </Space>
            <div>
              <Image preview={false} src={'https://ecolistico.com/img/logo.png'} alt={'Ecolistico'} width={300} height={51}/>
            </div>
          </Footer>
      </Layout>
  );
}


export default Dashboard;